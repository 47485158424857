<template>
  <a-modal
    :visible="visible"
    @cancel="null"
    :footer="null"
    class="student-subscription-modal"
  >
    <div class="modal-container">
      <div class="content-wrapper">
        <div class="content-wrapper__logo-box">
          <img
            class="logo"
            src="../../assets/images/Amblem2@2x.webp"
            alt="Glocal Logo"
          />
        </div>
        <h1 class="content-wrapper__heading">Subscribe to Glocal</h1>
        <p class="content-wrapper__sub-heading">
          Access your open source environment
        </p>
        <form
          data-cy="signUpForm"
          class="content-wrapper__form"
          @submit.prevent="submitSignupForm"
        >
          <div class="form-fields">
            <input
              type="email"
              class="form-input"
              required
              placeholder="Email Address"
              v-model.trim="signupEmail"
            />
            <input
              type="text"
              class="form-input"
              required
              placeholder="Full name / Pseudonym"
              v-model="fullName"
            />
            <input
              type="text"
              class="form-input"
              required
              placeholder="Institution Code"
            />
            <a-input-password
              v-model:value="signupPassword"
              required
              placeholder="Password"
              class="form-input"
            />
            <p v-if="signupError" data-cy="signupError" class="error">
              {{ signupError }}
            </p>
            <div class="important-notes">
              <div class="important-notes__header">
                <img src="@/assets/icons/info-blue.svg" />
                <h2>Important Notes:</h2>
              </div>
              <div class="important-notes__body">
                <ul>
                  <li>
                    After activating your account you begin a 2 week free trial
                    with Glocal. You can always extend your membership in
                    account settings.
                  </li>
                  <li>
                    If you are applying with institute code, consult with your
                    organisation to determine the duration of your access to
                    Glocal.
                  </li>
                </ul>
              </div>
            </div>
            <a-button
              class="active-btn"
              htmlType="submit"
              :loading="onSignupRequest"
            >
              Activate Account
            </a-button>
            <p class="agreement-text">
              By creating an account, you agree to Glocal’s
              <router-link to="">
                user agreement
              </router-link>
              and
              <router-link
                target="_blank"
                to="/the-privacy"
                data-cy="privacyLink"
              >
                privacy
              </router-link>
              <router-link
                target="_blank"
                to="/the-policy"
                data-cy="policyLink"
              >
                policy.
              </router-link>
            </p>
          </div>
        </form>
      </div>
    </div>
  </a-modal>
</template>

<script>
export default {
  props: {
    visible: Boolean,
  },
  emits: [
    "showForgotPasswordModal",
    "show-modal",
    "showSubscriptionModal",
    "closeAccessModal",
  ],
  data() {
    return {
      loginEmail: "",
      loginPassword: "",
      error: "",
      showForgotPasswodModal: false,
      onLoginRequest: false,
      showAccActModal: false,
      verificationError: false,
      emailLinkSuccess: false,
      onVerificationRequest: false,
      showLoginModal: false,
      showSignUpModal: false,
      onSignupRequest: false,
      fullName: "",
      signupEmail: "",
      signupPassword: "",
      signupError: "",
    };
  },
  methods: {
    closeModal() {
      this.$emit("closeAccessModal", false);
    },
    forgotPassword() {
      this.$emit("showForgotPasswordModal");
    },
    goToSignup() {
      this.emitter.emit("changeFormType", "signup");
      (this.showLoginModal = false), (this.showSignUpModal = true);
    },
    goToLogin() {
      this.emitter.emit("changeFormType", "login");
      (this.showSignUpModal = false), (this.showLoginModal = true);
    },
    async submitSignupForm() {
      try {
        this.onSignupRequest = true;
        this.error = "";
        this.signupError = "";

        const payload = {
          email: this.signupEmail,
          password: this.signupPassword,
          confirm_password: this.signupPassword,
          profile: {
            full_name: this.fullName,
            phone_number: "+923101234567",
          },
        };
        const response = await this.$store.dispatch("auth/signUp", payload);

        if (response.status === 201) {
          await this.$store.dispatch("auth/login", payload);
          await this.$router.replace(`/discover`);
        }
      } catch (error) {
        if (error.response.data.email) {
          this.signupError = error.response.data.email[0];
        } else {
          this.signupError = error.response.data.password[0];
        }
      }
      this.onSignupRequest = false;
    },
  },
};
</script>

<style lang="scss">
.ant-modal-mask {
  backdrop-filter: blur(6px) !important;
  -webkit-backdrop-filter: blur(6px) !important;
}

.student-subscription-modal {
  width: 95rem;
  top: 10rem;

  @include respond(tab-port) {
    width: 50rem !important;
    top: 5rem;
  }

  .ant-modal-content {
    border-radius: 0;

    .ant-modal-close {
      display: none !important;
    }

    .ant-modal-body {
      padding: 0 !important;

      .modal-container {
        display: flex;
        padding: 4rem 3.7rem;
        .content-wrapper {
          &__logo-box {
            margin-bottom: 2.4rem;

            .logo {
              width: 3.6rem;
              display: block;
              margin: auto;
            }
          }

          &__heading {
            text-align: center;
            font-size: 2rem;
            font-family: $font-primary-bold;
            margin-bottom: 0.8rem;
            color: $color-black;
            line-height: 2.7rem;
          }

          &__sub-heading {
            font-size: 1.6rem;
            font-family: $font-primary;
            color: #757575;
            line-height: 2.1rem;
            text-align: center;
            margin-bottom: 2.9rem;
          }

          &__para {
            text-align: center;
            padding: 0 4rem;
            margin-bottom: 2.8rem;
          }
          &__form {
            .form-fields {
              display: flex;
              flex-direction: column;
              padding: 0;

              .important-notes {
                margin-top: 0.5rem;

                &__header {
                  display: flex;
                  align-items: center;
                  margin-bottom: 1.7rem;

                  img {
                    margin-right: 1rem;
                    width: 2rem;
                    height: auto;
                  }

                  h2 {
                    font-size: 1.6rem;
                    font-family: $font-primary-medium;
                    color: $color-black;
                    line-height: 2.1rem;
                    text-align: center;
                    margin-bottom: 0;
                  }
                }

                &__body {
                  ul {
                    padding-left: 2rem;
                    padding-right: 1rem;
                    li {
                      font-size: 1.2rem;
                      font-family: $font-primary;
                      color: #787878;
                      line-height: 1.6rem;
                      margin-bottom: 1.4rem;

                      &:last-child {
                        margin-bottom: 0;
                      }
                    }
                  }
                }
              }

              .form-input {
                height: 4.6rem;
                border: 1px solid #24242433;
                margin-bottom: 2rem;
                padding: 0 1.4rem;
                font-size: 1.6rem;
                font-family: $font-primary;
                box-shadow: none;
                outline: none;
                color: $color-black !important;
                border-radius: 7px !important;
                background-color: $color-white !important;
                &::placeholder {
                  opacity: 1;
                  color: #7b7b7b;
                }
              }
              .ant-input-affix-wrapper {
                padding: 0 !important;
                .ant-input-suffix {
                  margin: 0 1.4rem;
                  .anticon {
                    font-size: 2rem;
                  }
                }
                .ant-input {
                  font-size: 1.6rem;
                  font-family: $font-primary;
                  color: $color-black;
                  background-color: transparent;
                  padding-left: 1.4rem;
                  &::placeholder {
                    opacity: 1;
                    color: #7b7b7b;
                  }
                }
              }

              .active-btn {
                font-family: $font-primary-medium;
                font-size: 1.6rem;
                height: 4.6rem;
                margin-top: 1.5rem;
                outline: none;
                border: none;
                cursor: pointer;
                border-radius: 7px !important;
                height: 4.6rem;
                background-color: $color-primary;
                color: $color-white;
              }
              .agreement-text {
                opacity: 1;
                font-size: 1.6rem;
                font-family: $font-primary;
                color: $color-black;
                padding: 0 4rem;
                line-height: 2.2rem;
                margin: 1.6rem 0 0;
                text-align: center;

                @include respond(tab-port) {
                  padding: 0;
                }

                a {
                  color: $color-primary;
                  cursor: pointer;
                  font-family: $font-primary-medium;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
